import React, { useEffect } from "react";
import { connect } from "react-redux";
import { trackingGA } from '@merchx-v2/helpers'
import { SEO } from 'components'
import { useSiteMetadata } from 'hooks/use-site-metadata';
import * as actions from "state/UnsubscribePage/actions";
import * as styles from "./UnsubscribePage.module.scss";

const getURLParams = (url) => {
  const args = /^[?#]/.test(url) ? url.slice(1) : url;
  return args.split("&").reduce((params, param) => {
    const [key, value] = param.split("=");
    params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
    return params;
  }, {});
};

const UnsubscribePage = ({ updateOptInListCustomer }) => {
  const { landing } = useSiteMetadata()

  useEffect(() => {
    trackingGA()
    const pathSearch = window.location.search;
    if (pathSearch) {
      const params = getURLParams(pathSearch);

      const customerId = params.customerId || "";
      if (customerId) {
        updateOptInListCustomer({ customerId, campaignId: landing.CAMPAIGN.id, workspaceId: landing.WORKSPACE_ID });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <SEO pathname='/unsubscribe' />
      <div className={styles.page}>
        <div className={styles.bodyContainer}>
          <div>
            We’re sorry to see you go! We’ve successfully unsubscribed you from
            any future emails.
          </div>
          <div>We appreciate your interest in our small fan community.</div>
          <p>
            If you change your mind and would still like to receive emails from
            us with offers to free gifts, exclusive merch and more, feel free to
            contact our support team!
          </p>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  updateOptInListCustomer: actions.updateOptInListCustomer,
};

export default connect(undefined, mapDispatchToProps)(UnsubscribePage);
